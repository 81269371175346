import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    system: {
      version: '1.1.7',
      color1: '',
      color2: '',
      logo: '',
      icon: 'fa-house',
      // baseURL: 'https://ppanel.pauloferraco.com.br/',
      baseURL: 'https://ppanel.radiohabblet.com.br/',
      // baseURL: 'https://ppanel.bubblet.com.br/',
      client_id: 1, // 1 radio, 2 bub
      tema_colors: 2, // tema do site, 2 radio, 3 bub
      apiEditor: '',
      mobile: false,
      modulesE: [],
      dev: Boolean
    },
    user: {
      id: 0,
      user: '',
      image: ''
    },
    logged: false,
    sysActive: Array,
    navbar: false,
    notif: false,
    textA: null,
    textW: null,
    modules: [],
    urlAPI: 'https://api.pauloferraco.com.br/v3/',
    dark: false
  },
  getters: {},
  mutations: {
    tema(state){
      let root = document.documentElement;
      root.style.setProperty("--color1", state.system.color1)
      root.style.setProperty("--color2", state.system.color2)
    },
    Alert(state, text){
      state.textA = text;
      setTimeout(() => {
        state.textA = null;
      }, 3500);
      // this.$store.commit('Alert', req.data.text)
    },
    Alert2(state, text){
      state.textW = text;

      setTimeout(() => {
        state.textW = null;
      }, 10000);
    },
    modules(state, data){
      state.system.modulesE = data
    },
    modulesAll(state, data){
      state.modules = data
    }
  },
  actions: {
    async init({commit}){
      const req = await axios.get('async/init')
      commit('init', req.data)
    },
    async verPerm(store, data){
      const req = await axios.post('async_util/permissions/get', {
        dir: data
      })
      store.commit('Alert', req.data.text)
      return req.data.permission

      // if(store.state.modules.includes(data)){
      //   store.commit('Alert', '1')
      //   return true;
      // }
      // store.commit('Alert', '0')
      // return false
    },
    async modExtra({commit}){
      const req = await axios.get('async_util/modules/get')
      commit('modules', req.data)
    },
    async getModules(store){
      const req = await axios.get('async_util/modules/get_all')
      store.commit('modulesAll', req.data);
    }
  },
  modules: {
  }
})
